import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"

import Layout2 from "../components/layout2"
import SEO from "../components/seo"
// import Member from '../components/dasTeamCommon/member'
import FooterSmall from "../components/footerSmall"
import HeroGastro from "../components/hero-gastro"

import GridImages from "../components/gridImages"
import AktuellMessageGastro from "../components/aktuellMessageGastro"

import { renderRichText } from "gatsby-source-contentful/rich-text"

import { parseOptions } from "../utils/praseOptions"

import "react-toastify/dist/ReactToastify.css"

import "../styles/pages/das-team.scss"
import "../styles/pages/gastro.scss"

const GastroPage = ({
  data: {
    allContentfulWetterentscheidSb,
    allContentfulGastronomiePage,
    header,
    allContentfulPdfFiles,
  },
}) => {
  const content = allContentfulGastronomiePage?.nodes?.[0]

  return (
    <Layout2>
      <SEO title="Home" />
      <div className="rinde">
        <HeroGastro fullScreen image={header.childImageSharp.fluid} />

        {allContentfulWetterentscheidSb?.nodes && (
          <AktuellMessageGastro {...allContentfulWetterentscheidSb?.nodes[0]} />
        )}

        <Container>
          <Row>
            <Col>
              <p className="subPage-text">
                Entdecke die stimmungsvolle Weihnachtswelt auf dem
                Kloster-Fahr-Areal und geniesse unsere leckeren Fondues mit
                ausgewählten, hofeigenen Zutaten. In der historischen Trotte
                Fahr verschmelzen Geschichte und Geselligkeit zu einem
                gemütlichen Ambiente.
              </p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col>
              <p className="infoTitle">
                {renderRichText(content.aktuelleBeschreibung, parseOptions)}
              </p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col lg="4" md="4">
              <p className="infoTitle">
                {renderRichText(content.offenText, parseOptions)}
              </p>
            </Col>

            <Col lg="6" md="8">
              <p className="infoClaim">
                {renderRichText(content.offenWoche, parseOptions)}
              </p>
              <p className="infoClaim">
                {renderRichText(content.offenWochenende, parseOptions)}
              </p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col lg="4" md="4">
              <p className="infoTitle">Angebot</p>

              <div className="pdf-container">
                {allContentfulPdfFiles?.edges?.[0].node?.speisekarteGastronomie
                  .file?.url && (
                  <a
                    className="pdf-url"
                    rel="noreferrer"
                    target="_blank"
                    href={
                      "https:" +
                      allContentfulPdfFiles?.edges?.[0].node
                        ?.speisekarteGastronomie.file?.url
                    }
                  >
                    Speisekarte PDF
                  </a>
                )}
              </div>
            </Col>

            <Col lg="6" md="8">
              <p className="infoClaim">
                {renderRichText(content.angebotDesc, parseOptions)}
              </p>
            </Col>
          </Row>
        </Container>

        {/* Reservierungs Container */}
        <Container>
          <Row className="member">
            {allContentfulGastronomiePage?.nodes[0]?.reservationImage?.file
              ?.url && (
              <Col lg="4" md="4">
                <img
                  className="ReservedImage"
                  src={
                    allContentfulGastronomiePage?.nodes[0]?.reservationImage
                      ?.file?.url
                  }
                  alt={
                    allContentfulGastronomiePage?.nodes?.reservationImage?.file
                      ?.fileName
                  }
                />
              </Col>
            )}
            <Col lg="6" md="8">
              <p className="infoClaim">
                {renderRichText(content.reservationenText, parseOptions)}
              </p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col lg="12" md="12">
              <p className="infoTitle">{content.anlassTitel}</p>
            </Col>

            <Col lg="12" md="12">
              <p className="infoClaim">
                {renderRichText(content.anlassText, parseOptions)}
              </p>
            </Col>
          </Row>
        </Container>

        <GridImages
          hasTitle
          titles={["Hildegarten", "Fährigarten", "Brunnen", "SommerBeiz"]}
          images={allContentfulGastronomiePage?.nodes?.[0]?.bilder}
        />

        <Container>
          <Row>
            <Col lg="12" md="12">
              {/* <p className="subPage-text">

        Gerne beraten wir Sie persönlich: <a href="mailto:gastronomie@fahr-erlebnis.ch">
          
          gastronomie@fahr-erlebnis.ch 
          </a> 
          Tel:
          <a href="tel:gastronomie@fahr-erlebnis.ch">
          
 044 750 01 01
          </a>
          </p> */}
              <p className="subPage-text">
                Gerne beraten wir Sie persönlich: gastronomie@fahr-erlebnis.ch
              </p>
            </Col>
          </Row>
        </Container>

        {/* <Container className="teamCont">
      {allContentfulTeam?.edges?.map((member) => {
        return <Member {...member?.node} />
      })}
    </Container> */}

        <FooterSmall />
      </div>
    </Layout2>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
//
export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "hero_gastro_winter.jpeg" }) {
      ...fluidImage
    }

    allContentfulWetterentscheidSb {
      nodes {
        title
        bild {
          file {
            url
          }
        }
        description {
          raw
        }
      }
    }

    allContentfulGastronomiePage {
      nodes {
        id
        aktuelleBeschreibung {
          raw
        }
        angebotDesc {
          raw
        }
        anlassText {
          raw
        }
        anlassTitel
        offenText {
          raw
        }
        offenWoche {
          raw
        }
        offenWochenende {
          raw
        }
        reservationImage {
          file {
            url
          }
        }
        reservationenText {
          raw
        }
        bilder {
          file {
            url
            fileName
          }
        }
      }
    }

    allContentfulPdfFiles {
      edges {
        node {
          id
          file {
            file {
              url
            }
          }

          speisekarteGastronomie {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`

export default GastroPage
