import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import {CSSTransition } from 'react-transition-group';
import { Container, Row, Col } from "reactstrap"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { parseOptions } from '../utils/praseOptions';

import cancelIcon from "../images/cancel.svg"

import '../styles/components/_aktuellMessageGastro.scss'




function AktuellMessageGastro ({ description, bild, title }) {
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(true)
    }, [])

    return (
      <CSSTransition 
        timeout={10000}
        classNames="alert"
        in={isActive}
        unmountOnExit
      >
        <section className="aktuell-containerGastro">
          <Container>
            <Row style={{ marginBottom: '10px'}}>
              <Col sm={12} md={6} lg={6} xl={12}
              style={{ marginBottom: '-10px'}}
              >
                <h2 className="title">
                  {title}
                </h2>
                <img onClick={() => setIsActive(false)} className="close-icon" src={cancelIcon} alt="close" />
              </Col>
              {/* <Col sm={12} md={6} lg={6} xl={12}
              style={{ marginBottom: '-5px'}}>
                <span className="date">
                  {date}
                </span>
              </Col> */}
            </Row>

            <Row>
              <Col sm={12} md={6} lg={6} xl={12}
              style={{ marginBottom: '-10px'}}>
                {bild?.file?.url && (
                  <img
                  style={{maxHeight:'250px'}}
                    src={bild.file.url}
                    alt="Company Thumbnail"
                    className="img-head"
                  />   
                )}
              </Col>
              <Col className="custom-col" sm={12} md={6} lg={6} xl={12}>
                <p className="subtitle">
                  {renderRichText(description, parseOptions)}
                </p>
              
              </Col>
            </Row>
          </Container>
      </section>
      </CSSTransition >
    )
}

export default AktuellMessageGastro;
